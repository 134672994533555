// src/SiteManagementSignup.js
import React, { useState } from 'react';
import './styles/siteManagement.css'; // Stil dosyası için
import Autocomplete from 'react-google-autocomplete';
import Popup from './components/popup';


function UserRegister() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        bireysel: '1'
    });
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Api URL',process.env.REACT_APP_API_URL)
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

        if (formData.password !== formData.confirmPassword) {
            setPopupMessage('Şifreler eşleşmiyor. Lütfen tekrar deneyin.');
            setPopupType('error');
            setShowPopup(true);
        } else if (!regex.test(formData.password)) {
            setPopupMessage('Şifre en az 6 karekter olmalı. Harf ve Rakam içermeli');
            setPopupType('error');
            setShowPopup(true);
        }
        else
        {
            try {
               
                const response = await fetch(`${process.env.REACT_APP_API_URL}/add-user-site`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData), // Form verilerini JSON formatında gönder
                });
    
                if (!response.ok) {
                    const errorText = await response.text();
                    setPopupMessage(errorText);
                    setPopupType('error');
                    setShowPopup(true);
                    throw new Error(errorText);
                }
                const ResponseText = await response.text();
                setPopupMessage(ResponseText);
                setPopupType('info');
                setShowPopup(true);
            } catch (error) {
                setPopupMessage('Kayıt işlemi yapılamadı : ' + error.message);
                setPopupType('error');
                setShowPopup(true);
            }
        }
    };

    return (
        <div className="signup-container">
            <h2>Site Yönetimi Üye Ol</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label>Ad:</label>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Soyad:</label>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Cep Telefonu:</label>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>E-posta Adresi:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Şifre:</label>
                        <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Şifreyi Tekrar Girin:</label>
                        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required
                        />
                    </div>
                </div>
                <button type="submit">Kaydol</button>
            </form>

            {showPopup && (
        <Popup
          message={popupMessage}
          type={popupType}
          onClose={() => setShowPopup(false)}
        />
      )}
        </div>
    );
}



export default UserRegister;
