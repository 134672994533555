import React from 'react';

// Inline styles for Popup component
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const popupStyle = {
  background: 'white',
  padding: '20px',
  borderRadius: '5px',
  maxWidth: '300px',
  textAlign: 'center',
};

const buttonStyle = {
  marginTop: '10px',
  padding: '8px 16px',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

// Color styles based on type
const errorStyle = {
  border: '1px solid #ff4d4d',
  color: '#ff4d4d',
};

const errorButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#ff4d4d',
};

const infoStyle = {
  border: '1px solid #4d94ff',
  color: '#4d94ff',
};

const infoButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#4d94ff',
};

function Popup({ message, type, onClose }) {
  const typeStyle = type === 'error' ? errorStyle : infoStyle;
  const buttonTypeStyle = type === 'error' ? errorButtonStyle : infoButtonStyle;

  return (
    <div style={overlayStyle}>
      <div style={{ ...popupStyle, ...typeStyle }}>
        <p>{message}</p>
        <button onClick={onClose} style={buttonTypeStyle}>Tamam</button>
      </div>
    </div>
  );
}

export default Popup;
