import React from "react";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import SiteManagementRegister from "./SiteManagementRegister";
import UserRegister from "./UserRegister";
import HomePage from "./homePage";


function App() {
 

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/RegisterSiteManagement" element={<SiteManagementRegister />} />
          <Route path="/Register" element={<UserRegister />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;