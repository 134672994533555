
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/App.css"; // Stil dosyasını özelleştirmek için


function HomePage() {
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false, // Kartların tam olarak görünmesini sağlar
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const listings = [
    {
      imageUrl: "https://emlaksat.com/wp-content/uploads/2024/02/01-525x328.jpeg", // Örnek resim URL'si
      title: "Güzelşehir Sitesinde Büyük Bahçeli Deniz Manzaralı Teraslı Villa",
      location: "Büyükçekmece, İstanbul",
      price: "20.750.000 TL",
      description:
        "GÜZELŞEHİR SİTESİNDE BÜYÜK BAHÇELİ DENİZ MANZARALI TERASLI VİLLA...",
    },
    {
      imageUrl: "https://emlaksat.com/wp-content/uploads/2024/08/1-3-525x328.jpg", // Örnek resim URL'si
      title: "Brand İstanbul'da Metrobüs Yanı Balkonlu Büyük Tip Boş 1+1 Daire",
      location: "Beylikdüzü, İstanbul",
      price: "3.400.000 TL",
      description:
        "Brand İstanbul Park'ta, balkonlu, büyük tip 1+1 daire...",
    },
    {
      imageUrl: "https://emlaksat.com/wp-content/uploads/2024/02/1-1-525x328.jpg", // Örnek resim URL'si
      title: "Yeni Moda Evlerinde, Tam Deniz ve Göl Manzaralı, Boş, 4+1 Daire",
      location: "Büyükçekmece, İstanbul",
      price: "14.400.000 TL",
      description:
        "Yeni Moda Evlerinde, tam deniz ve göl manzaralı, boş, satılık 4+1 daire...",
    },
    {
      imageUrl: "https://emlaksat.com/wp-content/uploads/2022/10/11-Balkon-01-1-525x328.jpg", // Örnek resim URL'si
      title: "Kağıthane Çağlayan Galleria Residence'da 90 m² 2+1 Satılık Daire",
      location: "İstanbul, Kağıthane",
      price: "4.500.000 TL",
      description:
        "Kağıthane Çağlayan'da bulunan Galleria Residence'da 90 m² 2+1 satılık daire...",
    },
  ];
 return (

<div className="card-container">
<h2>Güncel İlanlarımız</h2>
  <Slider {...settings}>
    {listings.map((listing, index) => (
      <div key={index} className="card">
        <img src={listing.imageUrl} alt={listing.title} className="card-image" />
        <div className="card-content">
          <h3>{listing.title}</h3>
          <p>{listing.location}</p>
          <p className="price">{listing.price}</p>
          <p className="description">{listing.description}</p>
        </div>
      </div>
    ))}
  </Slider>
</div>
  )
}


export default HomePage;