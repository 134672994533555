// src/Header.js
import React, { useState } from "react";
import "../styles/Header.css"; // Header için ayrı bir stil dosyası
import { Link } from "react-router-dom";

function Header() {

  const [showMenu, setShowMenu] = useState(false); // Menü durumu

  const toggleMenu = () => {
    setShowMenu((prev) => !prev); // Menü açma/kapama
  };


  return (
    <header className="header">
      <div className="logo-container">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Logo" />
          </Link>
        </div>
        <div className="site-info">
          <h1 className="site-title">SITEDENEMLAK.COM</h1>
          <p className="site-subtitle">Gayrimenkül Hizmet Ortaklığı</p>
        </div>
      </div>
      <nav className="nav-links">
        <a href="#home">Bireysel Kullanıcı Girişi</a>
        <a href="#home">Site Yönetim Girişi</a>
        <a href="#about">Evimi Satmak İstiyorum</a>
        <a href="#contact">Evimi Kiralamak İstiyorum</a>
      </nav>
      <div className="auth-buttons">
        <button className="signup" onClick={toggleMenu}>Üye Ol</button>
        {showMenu && ( // Menü durumu kontrolü
          <div className="dropdown-menu">
            <Link to="/Register">
              <button className="signup" onClick={toggleMenu}>Biresysel Kullanıcı</button>
            </Link>
            <Link to="/RegisterSiteManagement">
              <button className="signup" onClick={toggleMenu}>Site Yönetimi</button>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
