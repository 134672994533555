// src/SiteManagementSignup.js
import React, { useState } from 'react';
import './styles/siteManagement.css'; // Stil dosyası için
import Popup from './components/popup';
import { v4 as uuidv4 } from 'uuid';


function SiteManagementSignup() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        city: '',
        district: '',
        neighborhood: '',
        siteName: '',
        propertyType: '',
        kararDefteriPhoto: '',
        kimlikPhoto: '',
        rizaMetin: false,
        iletisimIzin: false,
        bankName: '',
        iban: '',
        beneficiaryName: '',
        kararDefteriPhotoPreview: '',
        kimlikPhotoPreview: '',
    });
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleImageChange = (e, imageField) => {
        const file = e.target.files[0];

        if (file) {
            // Önizleme için dosya URL'si oluştur
            const imagePreviewUrl = URL.createObjectURL(file);

            setFormData((prevData) => ({
                ...prevData,
                [imageField]: file,
                [`${imageField}Preview`]: imagePreviewUrl, // Önizleme URL'sini burada ayarlıyoruz
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

        // Resimlerin yüklü olup olmadığını kontrol et
        if (!formData.kararDefteriPhoto) {
            alert('Karar defteri fotoğrafı yüklenmeden ilerlenemez');
            return;
        }

        if (!formData.kimlikPhoto) {
            alert('Kimlik fotoğrafı eklenmeden ilerlenemez');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setPopupMessage('Şifreler eşleşmiyor. Lütfen tekrar deneyin.');
            setPopupType('error');
            setShowPopup(true);
            return;
        } else if (!regex.test(formData.password)) {
            setPopupMessage('Şifre en az 6 karakter olmalı. Harf ve rakam içermeli.');
            setPopupType('error');
            setShowPopup(true);
            return;
        }

        try {
            /*
            const formDataToSend = new FormData();
            formDataToSend.append('kararDefteriPhoto', formData.kararDefteriPhoto, `${uuidv4()}_${formData.kararDefteriPhoto.name}`);
            formDataToSend.append('KimlikPhoto', formData.kimlikPhoto, `${uuidv4()}_${formData.kimlikPhoto.name}`);
            formDataToSend.append('firstName', formData.firstName);
            formDataToSend.append('lastName', formData.lastName);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('password', formData.password);
            formDataToSend.append('city', formData.city);
            formDataToSend.append('district', formData.district);
            formDataToSend.append('neighborhood', formData.neighborhood);
            formDataToSend.append('siteName', formData.siteName);
            formDataToSend.append('propertyType', formData.propertyType);
            formDataToSend.append('rizaMetin', formData.rizaMetin);
            formDataToSend.append('iletisimIzin', formData.iletisimIzin);
            formDataToSend.append('bankName', formData.bankName);
            formDataToSend.append('iban', formData.iban);
            formDataToSend.append('beneficiaryName', formData.beneficiaryName);
*/
            const response = await fetch(`${process.env.REACT_APP_API_URL}/add-user-site`, {
                method: 'POST',
                body: JSON.stringify(formData), // FormData nesnesini gönder
            });

            if (!response.ok) {
                const errorText = await response.text();
                setPopupMessage(errorText);
                setPopupType('error');
                setShowPopup(true);
                throw new Error(errorText);
            }
            const responseText = await response.text();
            setPopupMessage(responseText);
            setPopupType('info');
            setShowPopup(true);
        } catch (error) {
            setPopupMessage('Kayıt işlemi yapılamadı: ' + error.message);
            setPopupType('error');
            setShowPopup(true);
        }
    };

    return (
        <div className="signup-container">
            <h2>Site Yönetimi Üye Ol</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label>Ad:</label>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Soyad:</label>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Cep Telefonu:</label>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>E-posta Adresi:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Şifre:</label>
                        <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Şifreyi Tekrar Girin:</label>
                        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>İl:</label>
                        <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>İlçe:</label>
                        <input type="text" name="district" value={formData.district} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Semt/Mahalle:</label>
                        <input type="text" name="neighborhood" value={formData.neighborhood} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Site Adı:</label>
                        <input type="text" name="siteName" value={formData.siteName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Mülk Tipi:</label>
                        <select name="propertyType" value={formData.propertyType} onChange={handleChange} required>
                            <option value="">Seçiniz</option>
                            <option value="Residential">Konut</option>
                            <option value="Commercial">Ticari</option>
                            <option value="Land">Arsa</option>
                            <option value="Other">Diğer</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Karar Defteri Fotoğrafı Yükle:</label>
                        <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'kararDefteriPhoto')} required />
                        {formData.kararDefteriPhotoPreview && (
                            <img src={formData.kararDefteriPhotoPreview} alt="Karar Defteri Önizleme" style={{ width: '100px', height: 'auto' }} />
                        )}
                    </div>
                    <div className="form-group">
                        <label>Kimlik Fotoğrafı Yükle:</label>
                        <input type="file"  accept="image/*" onChange={(e) => handleImageChange(e, 'kimlikPhoto')} required />
                        {formData.kimlikPhotoPreview && (
                            <img src={formData.kimlikPhotoPreview} alt="Kimlik Önizleme" style={{ width: '100px', height: 'auto' }} />
                        )}
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group" style={{ textAlign: "left" }}>
                        <label>
                            <input style={{ marginRight: "5px" }} type="checkbox" name="rizaMetin" checked={formData.rizaMetin} onChange={handleChange} required />
                            sitedenemlak.com açık rıza metnini okudum. Onaylıyorum
                        </label>
                        <label>
                            <input style={{ marginRight: "5px" }} type="checkbox" name="iletisimIzin" checked={formData.iletisimIzin} onChange={handleChange} required />
                            sitedenemlak.com ticari iletişim iznini okudum. Onaylıyorum
                        </label>
                    </div>
                </div>

                <h3>Banka Hesap Bilgileri</h3>

                <div className="form-row">
                    <div className="form-group">
                        <label>Banka Adı:</label>
                        <input type="text" name="bankName" value={formData.bankName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>IBAN:</label>
                        <input type="text" name="iban" value={formData.iban} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Alıcı Ad Soyad:</label>
                        <input type="text" name="beneficiaryName" value={formData.beneficiaryName} onChange={handleChange} required />
                    </div>
                </div>

                <button type="submit">Kaydol</button>
            </form>

            {showPopup && (
                <Popup
                    message={popupMessage}
                    type={popupType}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
}

export default SiteManagementSignup;
